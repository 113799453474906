/*
  Please use this color naming tool if you need to add or change any color:
  http://chir.ag/projects/name-that-color
*/

:root {
  /* -------------------
       Fonts
  -------------------- */

  --fontName: 'Avenir';
  --fontSmoothing: antialiased;
  --headerFont: var(--fontName), arial, helvetica, sans-serif;
  --pageFont: var(--fontName), arial, helvetica, sans-serif;

  /* -------------------
      Base Sizes
  -------------------- */

  /* This is the single variable that controls them all */
  --emSize: 14px;

  /* The size of page text  */
  --fontSize: 14px;

  /* -------------------
        Sizes
-------------------- */

  /*
  Sizes are all expressed in terms of 14px/em (default em)
  This ensures these "ratios" remain constant despite changes in EM
*/

  --miniSize: (11 / 14);
  --tinySize: (12 / 14);
  --smallSize: (13 / 14);
  --mediumSize: (14 / 14);
  --largeSize: (16 / 14);
  --bigSize: (18 / 14);
  --hugeSize: (20 / 14);
  --massiveSize: (24 / 14);

  /* -------------------
    Brand Colors
  -------------------- */

  --primaryColor: var(--pink);
  --secondaryColor: var(--teal);
  --lightPrimaryColor: var(--lightBlue);
  --lightSecondaryColor: var(--lightBlack);

  /* -------------------
        Page
  -------------------- */

  --pageBackground: #fff;
  --pageOverflowX: hidden;
  --lineHeight: 1.4285em;
  --textColor: var(--shark);

  /* -------------------
      Paragraph
  -------------------- */

  --paragraphMargin: 0 0 1em;
  --paragraphLineHeight: var(--lineHeight);

  /* -------------------
       Links
  -------------------- */

  --linkColor: #4183c4;
  --linkUnderline: none;

  /* --linkHoverColor: darken(saturate(var(--linkColor), 20), 15, relative); */
  --linkHoverUnderline: var(--linkUnderline);

  /* -------------------
      RGB Colors
  -------------------- */

  --rgbBlue: 96, 212, 255;
  --rgbBrown: 165, 103, 63;
  --rgbChetwodeBlue: 147, 135, 210;
  --rgbDarkPurple: 89, 74, 155;
  --rgbDodgerBlue: 68, 195, 254;
  --rgbGreen: 47, 196, 178;
  --rgbGrey: 118, 118, 118;
  --rgbOlive: 181, 204, 24;
  --rgbOrange: 242, 113, 28;
  --rgbPink: 215, 106, 205;
  --rgbPurple: 89, 74, 155;
  --rgbRed: 254, 56, 36;
  --rgbShark: 29, 29, 38;
  --rgbSkyBlue: 0, 185, 255;
  --rgbTeal: 230, 248, 255;
  --rgbTorchRed: 236, 1, 30;
  --rgbViolet: 100, 53, 201;
  --rgbWildSand: 247, 246, 246;
  --rgbYellow: 252, 171, 83;
  --rgbBlack: 0, 0, 0;
  --rgbStiletto: 159, 58, 56;
  --rgbChablis: 255, 246, 246;
  --rgbCavernPink: 224, 180, 180;

  /* -------------------
      Site Colors
  -------------------- */

  /* ---  Colors  --- */
  --blue: rgb(var(--rgbBlue));
  --brown: rgb(var(--rgbBrown));
  --chetwodeBlue: rgb(var(--rgbChetwodeBlue));
  --dodgerBlue: rgb(var(--rgbDodgerBlue));
  --green: rgb(var(--rgbGreen));
  --grey: rgb(var(--rgbGrey));
  --olive: rgb(var(--rgbOlive));
  --orange: rgb(var(--rgbOrange));
  --pink: rgb(var(--rgbPink));
  --purple: rgb(var(--rgbPurple));
  --red: rgb(var(--rgbRed));
  --shark: rgb(var(--rgbShark));
  --teal: rgb(var(--rgbTeal));
  --torchRed: rgb(var(--rgbTorchRed));
  --violet: rgb(var(--rgbViolet));
  --wildSand: rgb(var(--rgbWildSand));
  --yellow: rgb(var(--rgbYellow));
  --darkPurple: rgb(var(--rgbDarkPurple));
  --skyBlue: rgb(var(--rgbSkyBlue));
  --stiletto: rgb(var(--rgbStiletto));
  --chablis: rgb(var(--rgbChablis));
  --cavernPink: rgb(var(--rgbCavernPink));

  /* ---  Light Colors  --- */
  --lightBlack: rgba(var(--rgbShark), 0.1);
  --lightenBlack: rgba(var(--rgbShark), 0.5);
  --lightBlue: rgba(var(--rgbBlue), 0.1);
  --lightBrown: rgba(var(--rgbBrown), 0.1);
  --lightGreen: rgba(var(--rgbGreen), 0.76);
  --lightGrey: rgba(var(--rgbGrey), 0.1);
  --lightOlive: rgba(var(--rgbOlive), 0.1);
  --lightOrange: rgba(var(--rgbOrange), 0.1);
  --lightPink: rgba(var(--rgbPink), 0.1);
  --lightRed: rgba(var(--rgbRed), 0.1);
  --lightTeal: rgba(var(--rgbTeal), 0.1);
  --lightViolet: rgba(var(--rgbViolet), 0.1);
  --lightYellow: rgba(var(--rgbYellow), 0.1);

  /* ---   Neutrals  --- */
  --alabaster: #fafafa;
  --alto: #ddd;
  --black: #000;
  --darkWhite: #e5e5e5;
  --doveGray: #666;
  --gallery: #eee;
  --gray: #7e7e7e;
  --grayedWhite: #ececec;
  --iron: #dededf;
  --mercury: #e3e3e3;
  --midWhite: #f8f8f8;
  --offWhite: #e0e0e0;
  --white: #fff;
  --silver: #ccc;
  --manatee: #8e8e93;
  --lilyWhite: #e4f8ff;
  --dustyGray: #999;
  --viking: #50d3c2;
  --radicalRed: #f36;
  --redOrange: #fe3824;
}
